/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  SelectUI,
  InputUI,
  ButtonUI,
  ImageUI,
  CodeUI,
  ModalLiteUI,
  ToggleUI,
} from '../../../components/common';
import { withStyles } from '@mui/styles';
import { Config, Validate } from '../../../other';
import { InputColorCircle, InfoText, ShopProductsFeeder } from '.';

const obtener_cantidad_imagenes = (grid_type) => {
  if (grid_type === 0) {
    return 2;
  } else if (Validate.in_array(grid_type, [1, 2])) {
    return 3;
  } else if (Validate.in_array(grid_type, [3, 4])) {
    return 4;
  } else {
    return 5;
  }
};

const BlockEdit_ = ({
  template_id,
  classes,
  bloques,
  selects,
  active_page,
  handleChangeBlock,
  handleChangeFileBlock,
  handleChangeSelectBlock,
  handleChangeMenuTab,
  handleChangeGridDesign,
  handleChangeProductsBlock,
  handlerShowAlertUI,
}) => {
  let bloque = bloques[active_page];
  let html;

  //usado en componente html
  const [bloque_10_modal_state, setModalState] = useState(false);
  const toggleModal = () => setModalState(!bloque_10_modal_state);

  //usado en bloque 3
  const [bloque_3_open, setBlock3Open] = useState(false);
  const toggleBlock3Options = () => setBlock3Open(!bloque_3_open);

  //alineaciones para templates fijos
  const show_align = template_id !== 8;

  switch (bloque.bl_tipo.value) {
    //NOMBRE: Carrusel de banners
    case 1:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText
              title="Banners"
              subtitle="Dimensiones recomendadas: 1920x677 o 1920x800 píxeles"
            />
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              enable_url
              data={bloque.bl_data.items}
              max_files={6}
              max_size={5}
              allowed_files={['jpg', 'png', 'jpeg', 'gif']}
              input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
              handleChange={handleChangeFileBlock}
              cdn_prefix={Config.CLOUDFRONT_BANNERS_CDN}
              upload_path="banner"
              row_height="90px"
              reducer_max_width={1920}
              reducer_max_height={1920}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.width}
                  options={selects.bloque_1.width}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.autoplay}
                  options={selects.bloque_si_no}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <ToggleUI show={bloque.bl_data.autoplay.value ? true : false}>
                <Grid item xs={12}>
                  <InputUI
                    input={bloque.bl_data.seconds}
                    handleChange={handleChangeBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Products feed [UNIQUE]
    case 2:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Título (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.title_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Texto (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.text_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText
              title="Productos"
              subtitle="Para modificar el orden de los productos, podés hacerlo desde la pestaña de productos."
            />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ButtonUI
                  type="callf"
                  onClickFunc={(e) => handleChangeMenuTab(e, -5)}
                  variant="outlined"
                  color="primary"
                  size="small"
                  label="Ver productos"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.section_size}
                  options={selects.bloque_espaciado}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.show_more_btn}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Products set
    case 3:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText first title="Productos" />
            <ShopProductsFeeder
              handlerShowAlertUI={handlerShowAlertUI}
              productos={bloque.bl_data.products}
              max_products={12}
              handleChangeProducts={(products) =>
                handleChangeProductsBlock(
                  bloque.bl_data.title.change_param,
                  products
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.toggle_opciones}
              onClick={() => toggleBlock3Options()}
            >
              {bloque_3_open
                ? 'OCULTAR OPCIONES DE CONFIGURACIÓN'
                : 'MOSTRAR MÁS OPCIONES DE CONFIGURACIÓN'}
            </Typography>
          </Grid>
          <ToggleUI show={bloque_3_open}>
            <Grid item xs={12}>
              <InfoText title="Título (opcional)" />
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <InputUI
                    input={bloque.bl_data.title}
                    handleChange={handleChangeBlock}
                  />
                </Grid>
                <ToggleUI show={show_align}>
                  <Grid item xs={12}>
                    <SelectUI
                      data={bloque.bl_data.title_align}
                      options={selects.bloque_alineacion}
                      handleChangeSelect={handleChangeSelectBlock}
                    />
                  </Grid>
                </ToggleUI>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InfoText title="Texto (opcional)" />
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <InputUI
                    input={bloque.bl_data.text}
                    handleChange={handleChangeBlock}
                    multiline
                  />
                </Grid>
                <ToggleUI show={show_align}>
                  <Grid item xs={12}>
                    <SelectUI
                      data={bloque.bl_data.text_align}
                      options={selects.bloque_alineacion}
                      handleChangeSelect={handleChangeSelectBlock}
                    />
                  </Grid>
                </ToggleUI>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InfoText title="Adicionales" />
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.type}
                    options={selects.bloque_display}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.per_row}
                    options={selects.bloque_row}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.per_row_mobile}
                    options={selects.bloque_row_mobile}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChangeBlock}
                    input={bloque.bl_data.background}
                    adornment={
                      <InputColorCircle
                        color={bloque.bl_data.background.value}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.section_size}
                    options={selects.bloque_espaciado}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ToggleUI>
        </Grid>
      );
      break;

    //NOMBRE: Lista de categorías
    case 4:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={bloque.bl_data.title}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={bloque.bl_data.type}
              options={selects.bloque_4.type}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Galeria
    case 5:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Título (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.title_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Texto (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.text_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText
              title="Galería"
              subtitle="Recomendamos que todas las imágenes sean del mismo tamaño."
            />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ImageUI
                  handlerShowAlertUI={handlerShowAlertUI}
                  data={bloque.bl_data.items}
                  max_files={6}
                  max_size={4}
                  allowed_files={['jpg', 'png', 'jpeg', 'gif']}
                  input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
                  handleChange={handleChangeFileBlock}
                  cdn_prefix={Config.CLOUDFRONT_BLOCKS_CDN}
                  upload_path="block"
                  row_height="76px"
                  reducer_max_width={960}
                  reducer_max_height={960}
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.gap}
                  options={selects.bloque_gap}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.per_row}
                  options={selects.bloque_row}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.per_row_mobile}
                  options={selects.bloque_row_mobile}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.masonry}
                  options={selects.bloque_si_no}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.section_size}
                  options={selects.bloque_espaciado}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Imagenes con link
    case 6:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Título (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.title_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Texto (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.text_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText
              title="Imágenes"
              subtitle="Recomendamos que todas las imágenes sean del mismo tamaño."
            />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ImageUI
                  handlerShowAlertUI={handlerShowAlertUI}
                  enable_url
                  enable_title
                  data={bloque.bl_data.items}
                  max_files={6}
                  max_size={4}
                  allowed_files={['jpg', 'png', 'jpeg', 'gif']}
                  input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
                  handleChange={handleChangeFileBlock}
                  cdn_prefix={Config.CLOUDFRONT_BLOCKS_CDN}
                  upload_path="block"
                  row_height="76px"
                  reducer_max_width={600}
                  reducer_max_height={600}
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.gap}
                  options={selects.bloque_gap}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.per_row}
                  options={selects.bloque_row}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.per_row_mobile}
                  options={selects.bloque_row_mobile}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.type}
                  options={selects.bloque_display}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.section_size}
                  options={selects.bloque_espaciado}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Texto
    case 7:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Título (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.title_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Texto" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.text_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Botón (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.button}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.button_align}
                  options={selects.bloque_alineacion}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.button_link}
                  handleChange={handleChangeBlock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.text_color}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.text_color.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.section_size}
                  options={selects.bloque_espaciado}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Información de compra
    case 8:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Item 1" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.icon_1}
                  options={selects.bloque_8.icons}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title_1}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text_1}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Item 2" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.icon_2}
                  options={selects.bloque_8.icons}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title_2}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text_2}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Item 3" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.icon_3}
                  options={selects.bloque_8.icons}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title_3}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text_3}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.text_color}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.text_color.value} />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Imagen con texto y boton
    case 9:
      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Título (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.title_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Texto (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
              <ToggleUI show={show_align}>
                <Grid item xs={12}>
                  <SelectUI
                    data={bloque.bl_data.text_align}
                    options={selects.bloque_alineacion}
                    handleChangeSelect={handleChangeSelectBlock}
                  />
                </Grid>
              </ToggleUI>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Botón (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.button}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.button_align}
                  options={selects.bloque_alineacion}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.button_link}
                  handleChange={handleChangeBlock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Imagen" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ImageUI
                  handlerShowAlertUI={handlerShowAlertUI}
                  data={bloque.bl_data.image}
                  max_files={1}
                  max_size={4}
                  allowed_files={['jpg', 'png', 'jpeg', 'gif']}
                  input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
                  handleChange={handleChangeFileBlock}
                  cdn_prefix={Config.CLOUDFRONT_BLOCKS_CDN}
                  upload_path="block"
                  row_height="270px"
                  reducer_max_width={600}
                  reducer_max_height={600}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.image_size}
                  options={selects.bloque_9.image_size}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.image_position}
                  options={selects.bloque_9.image_position}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.section_size}
                  options={selects.bloque_espaciado}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Codigo HTML
    case 10:
      let bloque_10_modal_subtitle = (
        <span>
          Si requerís agregar código CSS para éste bloque HTML, podés hacerlo
          desde la sección de Ajustes. Adicionalmente, podés utilizar los
          componentes del framework{' '}
          <u>
            <a
              href="https://getuikit.com"
              style={{ color: 'inherit' }}
              target="_blank"
            >
              UIkit
            </a>
          </u>{' '}
          para tu código que ya viene incluído en las plantillas.
        </span>
      );

      let bloque_10_modal = (
        <ModalLiteUI
          open={bloque_10_modal_state}
          title="Código HTML"
          subtitle={bloque_10_modal_subtitle}
          modalHandler={toggleModal}
          full_size
          id="codigo_html"
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <CodeUI
                input={bloque.bl_data.code}
                lang="html"
                width="100%"
                height="400px"
                handleChange={handleChangeBlock}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonUI
                type="callf"
                onClickFunc={toggleModal}
                label="Cerrar editor HTML"
              />
            </Grid>
          </Grid>
        </ModalLiteUI>
      );

      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            {bloque_10_modal}
            <InfoText title="Código HTML" subtitle={bloque_10_modal_subtitle} />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ButtonUI
                  type="callf"
                  onClickFunc={toggleModal}
                  variant="outlined"
                  color="primary"
                  size="small"
                  label="Abrir editor HTML"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    //NOMBRE: Grillas de imágenes con link
    case 11:
      let grids = [0, 1, 2, 3, 4, 5];
      let grid_type = bloque.bl_data.type;

      let grid_max_files = obtener_cantidad_imagenes(grid_type.value);

      let html_grids = grids.map((type) => {
        return (
          <Grid item xs={6} key={type}>
            <div
              className={
                type === grid_type.value
                  ? classes.block_11_border
                  : classes.block_11_border_empty
              }
            >
              <img
                alt="grid"
                className={classes.block_11_images}
                src={`${Config.CLOUDFRONT_CDN_PROD}/panel/builder/grid/grid-${type}.png`}
                onClick={() =>
                  handleChangeGridDesign(
                    type,
                    grid_type.change_param,
                    obtener_cantidad_imagenes(type)
                  )
                }
                onKeyDown={({ code }) =>
                  code === 'Enter' &&
                  handleChangeGridDesign(
                    type,
                    grid_type.change_param,
                    obtener_cantidad_imagenes(type)
                  )
                }
                tabIndex={0}
              />
            </div>
          </Grid>
        );
      });

      html = (
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Título (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.title}
                  handleChange={handleChangeBlock}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.title_align}
                  options={selects.bloque_alineacion}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Texto (opcional)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  input={bloque.bl_data.text}
                  handleChange={handleChangeBlock}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.text_align}
                  options={selects.bloque_alineacion}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Tipo de diseño" />
            <Grid container spacing={0.5}>
              {html_grids}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Imágenes" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ImageUI
                  handlerShowAlertUI={handlerShowAlertUI}
                  enable_title
                  enable_url
                  data={bloque.bl_data.items}
                  max_files={grid_max_files}
                  max_size={4}
                  allowed_files={['jpg', 'png', 'jpeg', 'gif']}
                  input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
                  handleChange={handleChangeFileBlock}
                  cdn_prefix={Config.CLOUDFRONT_BLOCKS_CDN}
                  upload_path="block"
                  row_height="76px"
                  reducer_max_width={960}
                  reducer_max_height={960}
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Adicionales" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChangeBlock}
                  input={bloque.bl_data.background}
                  adornment={
                    <InputColorCircle color={bloque.bl_data.background.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectUI
                  data={bloque.bl_data.section_size}
                  options={selects.bloque_espaciado}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;

    default:
      break;
  }

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InputUI input={bloque.bl_nombre} handleChange={handleChangeBlock} />
      </Grid>
      <Grid item xs={12}>
        {html}
      </Grid>
      <Grid item xs={12}>
        <ButtonUI
          type="callf"
          onClickFunc={(e) => handleChangeMenuTab(e, -2)}
          variant="outlined"
          color="primary"
          size="small"
          label="Volver"
        />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  block_11_border: {
    border: '2px solid ' + theme.palette.primary.main,
    lineHeight: 0,
    borderRadius: '4px',
  },
  block_11_border_empty: {
    border: '2px solid transparent',
    lineHeight: 0,
    borderRadius: '4px',
  },
  block_11_images: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    lineHeight: 0,
    borderRadius: '4px',
  },
  toggle_opciones: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.65rem',
    lineHeight: '1rem',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
    margin: '15px 0px 0px 0px',
  },
});

export const BlockEdit = withStyles(styles, { withTheme: true })(BlockEdit_);
