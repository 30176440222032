import React from 'react';
import { Box, Button, Grid, Icon } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToggleUI } from '../../../components/common';

export const TemplateSettings = ({ templates }) => {
  const router = useHistory();
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';
  const shop = useSelector((state) => state.tienda);
  const selectedTemplate = localStorage.getItem(
    `template-selected-${shop.idTienda}`
  );
  const template = templates.find(
    (el) =>
      el.id ===
      (selectedTemplate ? Number(selectedTemplate) : shop.Templates_idTemplates)
  );

  return (
    <Grid container>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          marginBottom: 2,
          display: 'flex',
          fontWeight: 500,
          borderRadius: '2px',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #53B755',
          gap: '10px',
          '& span': {
            color: '#53B755',
          },
        }}
      >
        <Icon>check_circle</Icon>
        Plantilla en uso: {template.nombre}
      </Box>
      <ToggleUI show={idCountry === 'ARG'}>
        <Button
          onClick={() => router.push('/selecciona-plantilla')}
          fullWidth
          variant="contained"
        >
          Ver todas las plantillas
        </Button>
      </ToggleUI>
    </Grid>
  );
};
