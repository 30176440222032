import React from 'react';
import { Grid } from '@mui/material';
import { InputUI, SelectUI, ToggleUI } from '../../../components/common';
import { InfoText, ShopProductsFeeder } from '.';
import { Validate } from '../../../other';

export const ShopProductsSettings = ({
  tienda,
  selects,
  settings,
  productos,
  handleChange,
  handleChangeSelect,
  handlerShowAlertUI,
  handleChangeFeedProducts,
}) => {
  return (
    <Grid container spacing={0.5}>
      {/* Listas de productos */}
      <Grid item xs={12}>
        <InfoText first title="Listas de productos" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={settings.products_feed_product_subtext_type}
              options={selects.products_feed_product_subtext_type}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <ToggleUI
            show={settings.products_feed_product_subtext_type.value === 0}
          >
            <Grid item xs={12}>
              <InputUI
                handleChange={handleChange}
                input={settings.products_feed_product_subtext}
              />
            </Grid>
          </ToggleUI>
          <ToggleUI
            show={settings.products_feed_product_subtext_type.value === 1}
          >
            <Grid item xs={12}>
              <SelectUI
                data={settings.products_feed_product_subtext_cuotas}
                options={selects.products_feed_product_subtext_cuotas}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </ToggleUI>
          <Grid item xs={12}>
            <SelectUI
              data={settings.products_feed_filter}
              options={selects.bloque_si_no}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Menú */}
      <Grid item xs={12}>
        <InfoText title="Menú" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={settings.other_menu_label_products}
            />
          </Grid>
          <ToggleUI show={tienda.t_mayorista === 1}>
            <Grid item xs={12}>
              <InputUI
                handleChange={handleChange}
                input={settings.other_menu_label_wholesaler}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>

      {/* Orden */}
      <Grid item xs={12}>
        <InfoText title="Orden" />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <SelectUI
              data={settings.products_feed_order}
              options={selects.products_feed_order}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <ToggleUI
            show={Validate.trim_int(settings.products_feed_order.value) === 4}
          >
            <Grid item xs={12}>
              <ShopProductsFeeder
                productos={productos}
                handlerShowAlertUI={handlerShowAlertUI}
                handleChangeProducts={handleChangeFeedProducts}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>

      {/* Detalle de producto */}
      <Grid item xs={12}>
        <InfoText title="Detalle de producto" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={settings.products_detail_photo_display}
              options={selects.products_detail_photo_display}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
