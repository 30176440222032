import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Config } from '../../../other';
import {
  ButtonUI,
  CardModuleUI,
  ModuleCardTitleUI,
  ToggleUI,
} from '../../../components/common';
import ReactExport from 'react-export-excel';
import ButtonMui from '../../../components/common/ButtonMui';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const RenderExcel = ({ productos }) => {
  return (
    <ExcelFile
      filename={'Productos-vendidos'}
      element={<ButtonUI label="Exportar" type="static" />}
    >
      <ExcelSheet data={productos} name="Productos-vendidos">
        <ExcelColumn label="Producto" value="nombre" />
        <ExcelColumn label="SKU" value="sku" />
        <ExcelColumn label="Cantidad total vendida" value="cantidad" />
      </ExcelSheet>
    </ExcelFile>
  );
};

const Productos = (props) => {
  let { productos_vendidos, showAll, classes } = props;

  let productos = productos_vendidos.slice(
    0,
    showAll ? productos_vendidos.length : 15
  );

  let body = productos.map((producto, idx) => {
    let table_class =
      productos.length - 1 === idx
        ? classes.table_cell_last
        : classes.table_cell;
    return (
      <TableRow hover key={idx}>
        <TableCell classes={{ root: table_class }}>
          <div className={classes.avatarContainer}>
            <Avatar
              alt="producto"
              src={Config.CLOUDFRONT_PRODUCTS_CDN + producto.imagen}
              className={classes.avatar}
            />
            {producto.nombre}
          </div>
        </TableCell>
        <TableCell classes={{ root: table_class }}>{producto.sku}</TableCell>
        <TableCell classes={{ root: table_class }}>
          {producto.cantidad}
        </TableCell>
      </TableRow>
    );
  });

  return body;
};

const generar_array_productos = (ventas) => {
  let productos_vendidos = [];
  let productos_vendidos_idStock = [];

  ventas.forEach((venta, idx) => {
    let carrito = JSON.parse(venta.v_arr_carrito_json);
    let idStock = Object.keys(carrito);

    idStock.forEach((id) => {
      if (productos_vendidos_idStock.includes(id)) {
        productos_vendidos = productos_vendidos.map((producto) => {
          if (producto.idStock === id) {
            return {
              ...producto,
              cantidad: producto.cantidad + carrito[id].cantidad,
            };
          } else {
            return producto;
          }
        });
      } else {
        let atributos = [];
        let atributos_list;
        if (carrito[id].atributos > 0) {
          for (let atributo in carrito[id].info_atributos) {
            atributos = [
              ...atributos,
              `(${carrito[id].info_atributos[atributo].at_nombre}: ${carrito[id].info_atributos[atributo].vat_valor})`,
            ];
          }
          atributos_list = atributos.join(' ');
        }

        productos_vendidos_idStock.push(id);

        productos_vendidos = [
          ...productos_vendidos,
          {
            idStock: id,
            cantidad: carrito[id].cantidad,
            nombre: `${carrito[id].nombre}${
              atributos_list ? ' ' + atributos_list : ''
            }`,
            imagen: carrito[id].img,
            sku: carrito[id].sku ? carrito[id].sku : '-',
          },
        ];
      }
    });
  });

  productos_vendidos.sort((a, b) => {
    if (a.cantidad < b.cantidad) {
      return 1;
    }
    if (a.cantidad > b.cantidad) {
      return -1;
    }
    return 0;
  });

  return productos_vendidos;
};

const MasVendidosList_ = (props) => {
  const [showAll, setShowAll] = useState(false);

  let { ventas, classes } = props;

  let title = 'Productos vendidos';
  let subtitle =
    'Solo se contabilizan los productos con ventas finalizadas en el periodo seleccionado.';

  if (ventas.length) {
    let productos = generar_array_productos(ventas);

    return (
      <CardModuleUI below disablePadding>
        <div className={classes.cardmodule_title_padding}>
          <Grid
            container
            spacing={1.5}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xl={10} md={10} xs={12}>
              <ModuleCardTitleUI title={title} subtitles={[subtitle]} />
            </Grid>
            <Grid item xl={1} md={2} xs={12}>
              <RenderExcel productos={productos} />
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Cantidad total vendida</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Productos
                productos_vendidos={productos}
                showAll={showAll}
                classes={classes}
              />
            </TableBody>
          </Table>
        </div>
        <ToggleUI show={productos.length > 15 && !showAll}>
          <div className={classes.button_showmore}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <ButtonMui
                  onClick={() => setShowAll(true)}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Ver más
                </ButtonMui>
              </Grid>
            </Grid>
          </div>
        </ToggleUI>
      </CardModuleUI>
    );
  } else {
    return (
      <CardModuleUI below>
        <ModuleCardTitleUI title={title} subtitles={[subtitle]} />
        <Typography className={classes.list_item_title}>
          No se han encontrado productos vendidos ya que no hay ventas
          finalizadas en dicho periodo
        </Typography>
      </CardModuleUI>
    );
  }
};

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  cardmodule_title_padding: {
    padding: '30px 20px',
  },
  list_item_title: {
    fontSize: '0.9rem',
    margin: '20px 0px 0px 0px',
    padding: 0,
  },
  table_cell: {},
  table_cell_last: {
    borderBottom: '0px solid transparent',
  },
  button_showmore: {
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: '20px',
    height: '25px',
    width: '25px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const mapStateToProps = (state) => {
  return {
    ventas: state.ventas,
  };
};

const mapDispatchToProps = null;

export const MasVendidosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MasVendidosList_));
