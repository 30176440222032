import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  tiendaObtener,
  bloqueObtenerBloques,
  tiendaActualizarTemplate,
  productoObtenerProductosOrdenados,
  tiendaGenerarPrevisualizacionTemplate,
} from '../../store/actions';
import { Validate, Regex, RegexExtra, Config } from '../../other';
import {
  DrawerLayout,
  DrawerContent,
  NavigationLayout,
  ShopPreview,
  BuilderWrapper,
} from './subcomponents';
import { AlertUI, ErrorLayout } from '../../components/common';
import {
  design_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../components/amplitude';
import * as braze from '@braze/web-sdk';
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { loadBlock } from './loadBlock';
import { getTemplateName } from '../../other/getTemplateName';
import { getCeresJson } from './jsonTemplates';
import { getCeresBlocks } from './jsonBlocks';

const BuilderPage_ = ({
  getShop,
  fetchBlocks,
  getSortedProducts,
  generateShopTemplatePreview,
  updateShopTemplate,
  tienda,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';
  const router = useHistory();
  const [state, setState] = useState({
    initialLoadingFinished: false,
    deviceActive: 'desktop',
    loading_tienda: true,
    loading_bloques: true,
    loading_productos_ord: true,
    loading_preview_html: true,
    loadingSpinnerMessage: 'Cargando editor...',
    saving_changes: false,
    uploading_images: false,
    error: false,
    active_page: -1,
    back_page: -1,
    drawer_status: false,
    drawer_title: '',

    settings: {
      template_id: {
        validate: false,
        value: 1,
      },
      t_logo: {
        validate: false,
        auth: true,
        uploading: false,
        required: false,
        label: '',
        id: 't_logo',
        sublabels: [''],
        files: [],
        messages: {
          help: '',
          error: '',
        },
      },
      t_favicon: {
        validate: false,
        auth: true,
        uploading: false,
        required: false,
        label: '',
        id: 't_favicon',
        sublabels: [],
        files: [],
        messages: {
          help: '',
          error: '',
        },
      },
      color: {
        validate: false,
        required: true,
        error: false,
        label: 'Color de plantilla',
        id: 'color',
        name: 'color',
        change_param: 'settings',
        value: 1,
        messages: {
          error: '',
        },
      },
      primary_text: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color de texto',
        id: '',
        name: 'primary_text',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      primary_background: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color primario',
        id: '',
        name: 'primary_background',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      primary_contrastText: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Contraste color primario',
        id: '',
        name: 'primary_contrastText',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      secondary_text: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color de texto secundario',
        id: '',
        name: 'secondary_text',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      secondary_background: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color secundario',
        id: '',
        name: 'secondary_background',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      secondary_contrastText: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Contraste color secundario',
        id: '',
        name: 'secondary_contrastText',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      body_background: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Fondo',
        id: '',
        name: 'body_background',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      font_title: {
        validate: false,
        required: true,
        error: false,
        label: 'Fuente de títulos',
        id: 'font_title',
        name: 'font_title',
        change_param: 'settings',
        value: 20,
        messages: {
          error: '',
        },
      },
      font_text: {
        validate: false,
        required: true,
        error: false,
        label: 'Fuente de textos',
        id: 'font_text',
        name: 'font_text',
        change_param: 'settings',
        value: 20,
        messages: {
          error: '',
        },
      },
      message_text: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje',
        id: '',
        name: 'message_text',
        change_param: 'settings',
        regex: Regex.DESCRIPCION_0_1000,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.DESCRIPCION_0_1000,
          error: '',
        },
      },
      message_link: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link',
        id: '',
        name: 'message_link',
        change_param: 'settings',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.LINK,
          error: 'Por favor, ingresa un link válido',
        },
      },
      css: {
        validate: false,
        name: 'css',
        id: '',
        change_param: 'settings',
        value: '',
        placeholder: 'Escriba o pegue su código CSS aquí',
      },
      products_feed_order: {
        validate: false,
        required: true,
        error: false,
        label: 'Orden de productos',
        id: 'products_feed_order',
        name: 'products_feed_order',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      products_feed_filter: {
        validate: false,
        required: true,
        error: false,
        label: 'Filtro de atributos',
        id: 'products_feed_filter',
        name: 'products_feed_filter',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
          help: 'Los filtros pueden demorar hasta 15 minutos en actualizarse o aparecer',
        },
      },
      products_feed_product_subtext_type: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de leyenda',
        id: 'products_feed_product_subtext_type',
        name: 'products_feed_product_subtext_type',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      products_feed_product_subtext: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Leyenda de productos',
        id: '',
        name: 'products_feed_product_subtext',
        change_param: 'settings',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese una leyenda válida',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      products_feed_product_subtext_cuotas: {
        validate: false,
        required: true,
        error: false,
        label: 'Cuotas sin interés ofrecidas',
        id: 'products_feed_product_subtext_cuotas',
        name: 'products_feed_product_subtext_cuotas',
        change_param: 'settings',
        value: 3,
        messages: {
          error: '',
        },
      },
      products_detail_photo_display: {
        validate: false,
        required: true,
        error: false,
        label: 'Visualización de fotos',
        id: 'products_detail_photo_display',
        name: 'products_detail_photo_display',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      products_detail_photo_zoom: {
        validate: false,
        required: true,
        error: false,
        label: 'Zoom de fotos',
        id: 'products_detail_photo_zoom',
        name: 'products_detail_photo_zoom',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      checkout_comments: {
        validate: false,
        required: true,
        error: false,
        label: 'Permitir notas',
        id: 'checkout_comments',
        name: 'checkout_comments',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      checkout_short_billing_form: {
        validate: false,
        required: true,
        error: false,
        label: 'Formulario de facturación simple',
        id: 'checkout_short_billing_form',
        name: 'checkout_short_billing_form',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      checkout_step_review: {
        validate: false,
        required: true,
        error: false,
        label: 'Paso de revisión',
        id: 'checkout_step_review',
        name: 'checkout_step_review',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      other_border_radius: {
        validate: false,
        required: true,
        error: false,
        label: 'Bordes redondeados',
        id: 'other_border_radius',
        name: 'other_border_radius',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      other_menu_label_products: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Menú de productos',
        id: '',
        name: 'other_menu_label_products',
        change_param: 'settings',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.STRING_GENERICO_2_45,
          error: '',
        },
      },
      other_menu_label_wholesaler: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Menú de mayoristas',
        id: '',
        name: 'other_menu_label_wholesaler',
        change_param: 'settings',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.STRING_GENERICO_2_45,
          error: '',
        },
      },
    },

    agregar: {
      bl_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de sección',
        id: 'bl_tipo',
        name: 'bl_tipo',
        change_param: 'agregar',
        value: 0,
        messages: {
          error: '',
        },
      },
    },

    bloques: [],

    products_feed: [],

    alert: {
      open: false,
      type: 'error',
      message: '',
    },

    selects: {
      macro_pages: [
        {
          value: -1,
          label: 'Personalización',
        },
        {
          value: -3,
          label: 'Plantillas',
        },
        {
          value: -2,
          label: 'Secciones',
        },
        {
          value: -5,
          label: 'Productos',
        },
        {
          value: -6,
          label: 'Checkout',
        },
      ],
      templates: [
        {
          nombre: 'Aura',
          id: 1,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/aura/black.jpg`,
          secondary: false,
        },
        {
          nombre: 'Eris',
          id: 9,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/eris-theme.png`,
          secondary: false,
        },
        {
          nombre: 'Elara',
          id: 10,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/elara-theme.jpg`,
          secondary: false,
        },
        {
          nombre: 'Mimas',
          id: 8,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/mimas-theme.jpg`,
          secondary: true,
        },
        {
          nombre: 'Favourite',
          id: 2,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/favourite/black.png`,
          secondary: false,
        },
        {
          nombre: 'Classic',
          id: 3,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/classic/black.png`,
          secondary: true,
        },
        {
          nombre: 'Ceres',
          id: 4,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/light/black.png`,
          secondary: false,
        },
        {
          nombre: 'Elegant',
          id: 5,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/elegant/black.png`,
          secondary: true,
        },
        {
          nombre: 'Simple',
          id: 6,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/simple/black.png`,
          secondary: true,
        },
        {
          nombre: 'Integral',
          id: 7,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/integral/black.png`,
          secondary: true,
        },
      ],
      colores: [
        {
          id: 1,
          gama: 'Negro',
          primary: {
            background: '#000000',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#1c1c1c',
            text: '#1c1c1c',
            contrastText: '#ffffff',
          },
        },
        {
          id: 2,
          gama: 'Gris',
          primary: {
            background: '#f0f4f1',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f6f6f6',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 3,
          gama: 'Rojo',
          primary: {
            background: '#ea3a3a',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#ed5758',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 4,
          gama: 'Rosa pastel',
          primary: {
            background: '#ffcae6',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 5,
          gama: 'Coral pastel',
          primary: {
            background: '#ffc0cb',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 6,
          gama: 'Celeste pastel',
          primary: {
            background: '#e0ffff',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 7,
          gama: 'Violeta pastel',
          primary: {
            background: '#e8d7ff',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 8,
          gama: 'Verde pastel',
          primary: {
            background: '#ddffe3',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 9,
          gama: 'Amarillo pastel',
          primary: {
            background: '#fcffcc',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 10,
          gama: 'Naranja pastel',
          primary: {
            background: '#ffdcba',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 11,
          gama: 'Azul pastel',
          primary: {
            background: '#cfdbff',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 12,
          gama: 'Magenta',
          primary: {
            background: '#74113f',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#74113f',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 13,
          gama: 'Verde',
          primary: {
            background: '#00ae66',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#17aa70',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 14,
          gama: 'Celeste',
          primary: {
            background: '#6594ec',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#89cdfa',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 15,
          gama: 'Naranja',
          primary: {
            background: '#ff7f00',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#fc9733',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 16,
          gama: 'Salmón',
          primary: {
            background: '#fa8071',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#dd999a',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 17,
          gama: 'Violeta',
          primary: {
            background: '#622582',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#6e748c',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 18,
          gama: 'Amarillo',
          primary: {
            background: '#ffe164',
            text: '#000000',
            contrastText: '#000000',
          },
          secondary: {
            background: '#1c1c1c',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 19,
          gama: 'Marrón',
          primary: {
            background: '#663200',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#c09a6b',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 20,
          gama: 'Azul',
          primary: {
            background: '#446CB3',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#6f96db',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 21,
          gama: 'Verde agua',
          primary: {
            background: '#47d6c4',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
      ],
      fonts: [
        {
          label: 'Alegreya',
          value: 1,
          font_family: "'Alegreya', serif",
          font_setting: 'Alegreya:wght@400;500;700',
        },
        {
          label: 'Asap Condensed',
          value: 2,
          font_family: "'Asap Condensed', sans-serif",
          font_setting: 'Asap+Condensed:wght@400;500;700',
        },
        {
          label: 'Baloo 2',
          value: 3,
          font_family: "'Baloo 2', cursive",
          font_setting: 'Baloo+2:wght@400;500;700',
        },
        {
          label: 'Balsamiq Sans',
          value: 4,
          font_family: "'Balsamiq Sans', cursive",
          font_setting: 'Balsamiq+Sans:wght@400;700',
        },
        {
          label: 'Bitter',
          value: 5,
          font_family: "'Bitter', serif",
          font_setting: 'Bitter:wght@400;700',
        },
        {
          label: 'Cairo',
          value: 6,
          font_family: "'Cairo', sans-serif",
          font_setting: 'Cairo:wght@300;400;700',
        },
        {
          label: 'Heebo',
          value: 7,
          font_family: "'Heebo', sans-serif",
          font_setting: 'Heebo:wght@300;400;500;700',
        },
        {
          label: 'Inconsolata',
          value: 8,
          font_family: "'Inconsolata', monospace",
          font_setting: 'Inconsolata:wght@300;400;500;700',
        },
        {
          label: 'Josefin Sans',
          value: 9,
          font_family: "'Josefin Sans', sans-serif",
          font_setting: 'Josefin+Sans:wght@300;400;500;700',
        },
        {
          label: 'Jost',
          value: 10,
          font_family: "'Jost', sans-serif",
          font_setting: 'Jost:wght@300;400;500;700',
        },
        {
          label: 'Kanit',
          value: 11,
          font_family: "'Kanit', sans-serif",
          font_setting: 'Kanit:wght@300;400;500;700',
        },
        {
          label: 'Lato',
          value: 12,
          font_family: "'Lato', sans-serif",
          font_setting: 'Lato:wght@300;400;700',
        },
        {
          label: 'Libre Baskerville',
          value: 13,
          font_family: "'Libre Baskerville', serif",
          font_setting: 'Libre+Baskerville:wght@400;700',
        },
        {
          label: 'Libre Caslon Text',
          value: 14,
          font_family: "'Libre Caslon Text', serif",
          font_setting: 'Libre+Caslon+Text:wght@400;700',
        },
        {
          label: 'Literata',
          value: 15,
          font_family: "'Literata', serif",
          font_setting: 'Literata:wght@400;500;700',
        },
        {
          label: 'Lora',
          value: 16,
          font_family: "'Lora', serif",
          font_setting: 'Lora:wght@400;500;700',
        },
        {
          label: 'Merriweather',
          value: 17,
          font_family: "'Merriweather', serif",
          font_setting: 'Merriweather:wght@300;400;700',
        },
        {
          label: 'Montserrat',
          value: 18,
          font_family: "'Montserrat', sans-serif",
          font_setting: 'Montserrat:wght@300;400;500;700',
        },
        {
          label: 'Nunito',
          value: 19,
          font_family: "'Nunito', sans-serif",
          font_setting: 'Nunito:wght@300;400;700',
        },
        {
          label: 'Open Sans',
          value: 20,
          font_family: "'Open Sans', sans-serif",
          font_setting: 'Open+Sans:wght@300;400;700',
        },
        {
          label: 'Oswald',
          value: 21,
          font_family: "'Oswald', sans-serif",
          font_setting: 'Oswald:wght@300;400;500;700',
        },
        {
          label: 'Playfair Display',
          value: 22,
          font_family: "'Playfair Display', serif",
          font_setting: 'Playfair+Display:wght@400;500;700',
        },
        {
          label: 'Poppins',
          value: 23,
          font_family: "'Poppins', sans-serif",
          font_setting: 'Poppins:wght@300;400;500;700',
        },
        {
          label: 'Raleway',
          value: 24,
          font_family: "'Raleway', sans-serif",
          font_setting: 'Raleway:wght@300;400;500;700',
        },
        {
          label: 'Roboto',
          value: 25,
          font_family: "'Roboto', sans-serif",
          font_setting: 'Roboto:wght@300;400;500;700',
        },
        {
          label: 'Rubik',
          value: 26,
          font_family: "'Rubik', sans-serif",
          font_setting: 'Rubik:wght@300;400;500;700',
        },
        {
          label: 'Teko',
          value: 27,
          font_family: "'Teko', sans-serif",
          font_setting: 'Teko:wght@300;400;500;700',
        },
        {
          label: 'Titillium Web',
          value: 28,
          font_family: "'Titillium Web', sans-serif",
          font_setting: 'Titillium+Web:wght@300;400;700',
        },
        {
          label: 'Trirong',
          value: 29,
          font_family: "'Trirong', serif",
          font_setting: 'Trirong:wght@300;400;500;700',
        },
        {
          label: 'Work Sans',
          value: 30,
          font_family: "'Work Sans', sans-serif",
          font_setting: 'Work+Sans:wght@300;400;500;700',
        },
      ],
      products_feed_order: [
        {
          value: 0,
          label: 'Más nuevo a más viejo',
        },
        {
          value: 1,
          label: 'Más viejo a más nuevo',
        },
        {
          value: 2,
          label: 'Precio menor a mayor',
        },
        {
          value: 3,
          label: 'Precio mayor a menor',
        },
        {
          value: 4,
          label: 'Orden establecido',
        },
      ],
      products_feed_product_subtext_type: [
        {
          value: 0,
          label: 'Mensaje con texto',
        },
        {
          value: 1,
          label: 'Mostrar cuotas sin interés ofrecidas',
        },
      ],
      products_feed_product_subtext_cuotas: [
        {
          value: 3,
          label: '3 cuotas sin interés',
        },
        {
          value: 6,
          label: '6 cuotas sin interés',
        },
        {
          value: 9,
          label: '9 cuotas sin interés',
        },
        {
          value: 12,
          label: '12 cuotas sin interés',
        },
        {
          value: 18,
          label: '18 cuotas sin interés',
        },
      ],
      products_detail_photo_display: [
        {
          value: 0,
          label: 'Carrusel',
        },
        {
          value: 1,
          label: 'Grilla con miniaturas',
        },
      ],
      other_border_radius: [
        {
          value: 0,
          label: 'Recto',
        },
        {
          value: 3,
          label: 'Redondeado pequeño',
        },
        {
          value: 6,
          label: 'Redondeado normal',
        },
        {
          value: 10,
          label: 'Redondeado grande',
        },
        {
          value: 20,
          label: 'Redondeado muy grande',
        },
      ],
      bloques: [
        {
          value: 0,
          label: 'Seleccionar una sección',
        },
        {
          value: 1,
          label: 'Carrusel de banners',
        },
        {
          value: 2,
          label: 'Feed principal de productos',
        },
        {
          value: 3,
          label: 'Conjunto de productos',
        },
        {
          value: 4,
          label: 'Lista de categorías',
        },
        {
          value: 5,
          label: 'Galería',
        },
        {
          value: 6,
          label: 'Imágenes con link',
        },
        {
          value: 11,
          label: 'Grillas de imágenes con link',
        },
        {
          value: 7,
          label: 'Texto',
        },
        {
          value: 8,
          label: 'Información de compra',
        },
        {
          value: 9,
          label: 'Imagen con texto y botón',
        },
        {
          value: 10,
          label: 'Código HTML (avanzado)',
        },
      ],
      bloque_1: {
        width: [
          {
            value: 0,
            label: 'Toda la pantalla',
          },
          {
            value: 1,
            label: 'Centrado',
          },
        ],
      },
      bloque_4: {
        type: [
          {
            value: 0,
            label: 'Categorías raíz',
          },
          {
            value: 1,
            label: 'Todas las categorías',
          },
        ],
        mode: [
          {
            value: 0,
            label: 'Mixto (lista y slider)',
          },
          {
            value: 1,
            label: 'Sólo slider',
          },
        ],
      },
      bloque_8: {
        icons: [
          {
            value: 0,
            label: 'Envío',
          },
          {
            value: 1,
            label: 'Pago online',
          },
          {
            value: 2,
            label: 'Pago seguro',
          },
          {
            value: 3,
            label: 'Whatsapp',
          },
          {
            value: 4,
            label: 'Pago efectivo',
          },
          {
            value: 5,
            label: 'Compra en casa',
          },
          {
            value: 6,
            label: 'Descuento / Oferta',
          },
          {
            value: 7,
            label: 'Devolución',
          },
          {
            value: 8,
            label: 'Dirección / Punto de entrega',
          },
          {
            value: 9,
            label: 'Email',
          },
          {
            value: 10,
            label: 'Teléfono',
          },
          {
            value: 11,
            label: 'Pago transferencia',
          },
        ],
      },
      bloque_9: {
        image_size: [
          {
            value: 0,
            label: '1/4',
          },
          {
            value: 1,
            label: '1/3',
          },
          {
            value: 2,
            label: '1/2',
          },
        ],
        image_position: [
          {
            value: 0,
            label: 'Izquierda',
          },
          {
            value: 1,
            label: 'Derecha',
          },
        ],
      },
      bloque_alineacion: [
        {
          value: 0,
          label: 'Centrado',
        },
        {
          value: 1,
          label: 'Izquierda',
        },
        {
          value: 2,
          label: 'Derecha',
        },
        {
          value: 3,
          label: 'Justificado',
        },
      ],
      bloque_espaciado: [
        {
          value: 0,
          label: 'Muy grande',
        },
        {
          value: 1,
          label: 'Grande',
        },
        {
          value: 2,
          label: 'Normal',
        },
        {
          value: 3,
          label: 'Pequeño',
        },
        {
          value: 4,
          label: 'Muy pequeño',
        },
        {
          value: 5,
          label: 'Sin espaciado',
        },
      ],
      bloque_gap: [
        {
          value: 0,
          label: 'Grande',
        },
        {
          value: 1,
          label: 'Normal',
        },
        {
          value: 2,
          label: 'Pequeño',
        },
        {
          value: 3,
          label: 'Sin espaciado',
        },
      ],
      bloque_si_no: [
        {
          value: 1,
          label: 'Si',
        },
        {
          value: 0,
          label: 'No',
        },
      ],
      bloque_row: [
        {
          value: 2,
          label: '2 por fila',
        },
        {
          value: 3,
          label: '3 por fila',
        },
        {
          value: 4,
          label: '4 por fila',
        },
      ],
      bloque_row_mobile: [
        {
          value: 1,
          label: '1 por fila',
        },
        {
          value: 2,
          label: '2 por fila',
        },
      ],
      bloque_display: [
        {
          value: 1,
          label: 'Carrusel',
        },
        {
          value: 2,
          label: 'Grilla',
        },
        {
          value: 3,
          label: 'Grilla Masonry',
        },
      ],
    },

    preview_width: isMobile ? 'mobile' : 'desktop',
    preview_html: '',
  });

  useEffect(() => {
    getShop((err, resp) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          loading_tienda: false,
          error: true,
        }));
      } else {
        const shop = resp.data;
        const selectedTemplate = localStorage.getItem(
          `template-selected-${shop.idTienda}`
        );
        let t_template_data;

        //4 === CERES
        if (selectedTemplate === '4') {
          t_template_data = getCeresJson(shop.t_template_data);
        } else {
          t_template_data = shop.t_template_data;
        }

        const template_id = shop.Templates_idTemplates;
        const t_logo = shop.t_logo;
        const t_favicon = shop.t_favicon;

        const settings = {
          ...state.settings,
          template_id: {
            ...state.settings.template_id,
            value: selectedTemplate ? Number(selectedTemplate) : template_id,
          },
          t_logo: {
            ...state.settings.t_logo,
            files: t_logo
              ? [
                  {
                    link: t_logo,
                    url: '',
                    title: '',
                    button: '',
                  },
                ]
              : [],
          },
          t_favicon: {
            ...state.settings.t_favicon,
            files: t_favicon
              ? [
                  {
                    link: t_favicon,
                    url: '',
                    title: '',
                    button: '',
                  },
                ]
              : [],
          },
          color: {
            ...state.settings.color,
            value: t_template_data.id,
          },
          primary_background: {
            ...state.settings.primary_background,
            auth: true,
            value: t_template_data.primary.background,
          },
          primary_text: {
            ...state.settings.primary_text,
            auth: true,
            value: t_template_data.primary.text,
          },
          primary_contrastText: {
            ...state.settings.primary_contrastText,
            auth: true,
            value: t_template_data.primary.contrastText,
          },
          secondary_background: {
            ...state.settings.secondary_background,
            auth: true,
            value: t_template_data.secondary.background,
          },
          secondary_text: {
            ...state.settings.secondary_text,
            auth: true,
            value: t_template_data.secondary.text,
          },
          secondary_contrastText: {
            ...state.settings.secondary_contrastText,
            auth: true,
            value: t_template_data.secondary.contrastText,
          },
          body_background: {
            ...state.settings.body_background,
            auth: true,
            value: t_template_data.body.background,
          },
          font_title: {
            ...state.settings.font_title,
            value: t_template_data.fonts.title,
          },
          font_text: {
            ...state.settings.font_text,
            value: t_template_data.fonts.text,
          },
          message_text: {
            ...state.settings.message_text,
            value: t_template_data.message.text,
          },
          message_link: {
            ...state.settings.message_link,
            value: t_template_data.message.link,
          },
          css: {
            ...state.settings.css,
            value: t_template_data.css,
          },
          products_feed_order: {
            ...state.settings.products_feed_order,
            value: t_template_data.products_feed.order,
          },
          products_feed_filter: {
            ...state.settings.products_feed_filter,
            value: t_template_data.products_feed.filter,
          },
          products_feed_product_subtext_type: {
            ...state.settings.products_feed_product_subtext_type,
            value: t_template_data.products_feed.product_subtext_type,
          },
          products_feed_product_subtext: {
            ...state.settings.products_feed_product_subtext,
            value: t_template_data.products_feed.product_subtext,
          },
          products_feed_product_subtext_cuotas: {
            ...state.settings.products_feed_product_subtext_cuotas,
            value: t_template_data.products_feed.product_subtext_cuotas,
          },
          products_detail_photo_display: {
            ...state.settings.products_detail_photo_display,
            value: t_template_data.products_detail.photo_display,
          },
          products_detail_photo_zoom: {
            ...state.settings.products_detail_photo_zoom,
            value: t_template_data.products_detail.photo_zoom,
          },
          checkout_comments: {
            ...state.settings.checkout_comments,
            value: t_template_data.checkout.comments,
          },
          checkout_short_billing_form: {
            ...state.settings.checkout_short_billing_form,
            value: t_template_data.checkout.short_billing_form,
          },
          checkout_step_review: {
            ...state.settings.checkout_step_review,
            value: t_template_data.checkout.step_review,
          },
          other_border_radius: {
            ...state.settings.other_border_radius,
            value: t_template_data.other.border_radius,
          },
          other_menu_label_products: {
            ...state.settings.other_menu_label_products,
            value: t_template_data.other.menu_label_products,
          },
          other_menu_label_wholesaler: {
            ...state.settings.other_menu_label_wholesaler,
            value: t_template_data.other.menu_label_wholesaler,
          },
        };

        setState((prevState) => ({
          ...prevState,
          drawer_title: '',
          settings: settings,
          loading_tienda: false,
          error: prevState.error || false,
        }));

        fetchBlocks((err, resp) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              loading_bloques: false,
              error: true,
            }));
          } else {
            const isTemplateSelectedCeres = selectedTemplate === '4';
            let blocksArray;

            if (isTemplateSelectedCeres && resp.data.length <= 1) {
              blocksArray = getCeresBlocks();
            } else {
              blocksArray = resp.data;
            }

            const bloques = blocksArray.map((bloque) => {
              return loadBlock(
                bloque.bl_tipo,
                state.bloques.length,
                true,
                bloque
              );
            });

            setState((prevState) => ({
              ...prevState,
              loading_bloques: false,
              error: prevState.error || false,
              bloques: bloques,
            }));
          }
        });
      }
    });

    getSortedProducts((err, resp) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          loading_productos_ord: false,
          error: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          loading_productos_ord: false,
          error: prevState.error || false,
          products_feed: resp.data,
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (
      !state.loading_tienda &&
      !state.loading_bloques &&
      !state.loading_productos_ord &&
      !state.initialLoadingFinished
    ) {
      handleUpdatePreview();
      setState((prevState) => ({
        ...prevState,
        initialLoadingFinished: true,
      }));
    }
  }, [state]);

  const toggleDrawerOpen = () =>
    setState((prevState) => ({
      ...prevState,
      drawer_status: !prevState.drawer_status,
    }));

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const handleChangeMenuTab = (e, new_page) => {
    if (e) e.preventDefault();

    if (!state.uploading_images) {
      if (state.active_page >= 0) {
        const bloque = state.bloques[state.active_page];

        if (bloque.bl_tipo.value === 10) {
          if (
            !Validate.contiene_substring(bloque.bl_data.code.value, 'http://')
          ) {
            if (
              !Validate.contiene_substring(bloque.bl_data.code.value, '<?') &&
              !Validate.contiene_substring(bloque.bl_data.code.value, '?>')
            ) {
              setState((prevState) => ({
                ...prevState,
                active_page: Validate.trim_int(new_page),
                back_page: new_page >= 0 ? -2 : -1,
                drawer_title: '',
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                alert: {
                  ...prevState.alert,
                  open: true,
                  message: 'No es posible escribir código PHP',
                  type: 'error',
                },
              }));
            }
          } else {
            setState((prevState) => ({
              ...prevState,
              alert: {
                ...prevState.alert,
                open: true,
                message:
                  'Los links e imágenes ingresados en el código HTML deben ser usando HTTPS',
                type: 'error',
              },
            }));
          }
        } else if (
          Validate.validar_formulario(bloque) &&
          Validate.validar_formulario(bloque.bl_data)
        ) {
          let flag = true;
          let message = '';

          if ('image' in bloque.bl_data) {
            if (!bloque.bl_data.image.auth) {
              flag = false;
              message = 'Debes cargar la imagen';
            }
          } else if ('items' in bloque.bl_data) {
            if (!bloque.bl_data.items.auth) {
              flag = false;
              message = 'Debes cargar las imágenes';
            }
          }

          if (flag) {
            setState((prevState) => ({
              ...prevState,
              active_page: Validate.trim_int(new_page),
              back_page: new_page >= 0 ? -2 : -1,
              drawer_title: '',
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              alert: {
                open: true,
                type: 'error',
                message: message,
              },
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            alert: {
              ...prevState.alert,
              open: true,
              message: 'Por favor, revisa los campos',
              type: 'error',
            },
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          active_page: Validate.trim_int(new_page),
          back_page: new_page >= 0 ? -2 : -1,
          drawer_title: '',
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que se terminen de cargar las imágenes',
        },
      }));
    }
  };

  const handleChange = (e, blur, aditional) => {
    const value = e.target.value;
    const name = e.target.name;

    const stateAux = {
      [aditional]: {
        ...state[aditional],
        [name]: {
          ...state[aditional][name],
          value: value,
        },
      },
    };

    Validate.validate_input(stateAux[aditional][name], blur, (input) => {
      setState((prevState) => ({
        ...prevState,
        [aditional]: {
          ...prevState[aditional],
          [name]: input,
        },
      }));
    });
  };

  const handleChangeBlock = (e, blur, change_param) => {
    const value = e.target.value;
    let name = e.target.name;

    const bloques = state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        if (Validate.in_array(name, ['bl_nombre'])) {
          let input = bloque[name];
          input.value = value;
          input = Validate.validate_input_sync(input, blur);
          return {
            ...bloque,
            [name]: input,
          };
        } else {
          let nameArr = name.split('bldata_');
          name = nameArr[1];

          let input = bloque.bl_data[name];
          input.value = value;
          input = Validate.validate_input_sync(input, blur);

          return {
            ...bloque,
            bl_data: {
              ...bloque.bl_data,
              [name]: input,
            },
          };
        }
      } else {
        return bloque;
      }
    });

    const drawer_title = name === 'bl_nombre' ? value : state.drawer_title;

    setState((prevState) => ({
      ...prevState,
      drawer_title: drawer_title,
      bloques: bloques,
    }));
  };

  const handleChangeProductsBlock = (change_param, products) => {
    let bloques = state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            products: products,
          },
        };
      } else {
        return bloque;
      }
    });
    setState((prevState) => ({
      ...prevState,
      bloques: bloques,
    }));
  };

  const handleChangeSelect = (e, aditional) => {
    const name = e.target.name;
    const value = Validate.trim_int(e.target.value);

    switch (name) {
      case 'color':
        const color = state.selects.colores.filter(
          (color) => color.id === value
        )[0];

        setState((prevState) => ({
          ...prevState,
          settings: {
            ...prevState.settings,
            color: {
              ...prevState.settings.color,
              value: value,
            },
            primary_background: {
              ...prevState.settings.primary_background,
              auth: true,
              error: false,
              value: color.primary.background,
            },
            primary_text: {
              ...prevState.settings.primary_text,
              auth: true,
              error: false,
              value: color.primary.text,
            },
            primary_contrastText: {
              ...prevState.settings.primary_contrastText,
              auth: true,
              error: false,
              value: color.primary.contrastText,
            },
            secondary_background: {
              ...prevState.settings.secondary_background,
              auth: true,
              error: false,
              value: color.secondary.background,
            },
            secondary_text: {
              ...prevState.settings.secondary_text,
              auth: true,
              error: false,
              value: color.secondary.text,
            },
            secondary_contrastText: {
              ...prevState.settings.secondary_contrastText,
              auth: true,
              error: false,
              value: color.secondary.contrastText,
            },
          },
        }));
        break;

      default:
        setState((prevState) => ({
          ...prevState,
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
          },
        }));
        break;
    }
  };

  const handleChangeSelectBlock = (e, change_param) => {
    const nameArr = e.target.name.split('bldata_');
    const name = nameArr[1];
    const value = Validate.trim_int(e.target.value);

    const bloques = state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            [name]: {
              ...bloque.bl_data[name],
              value: value,
            },
          },
        };
      } else {
        return bloque;
      }
    });

    setState((prevState) => ({
      ...prevState,
      bloques: bloques,
    }));
  };

  const handleChangeFileSettings = (data) => {
    setState((prevState) => ({
      ...prevState,
      uploading_images: data.uploading,
      settings: {
        ...prevState.settings,
        [data.id]: data,
      },
    }));
  };

  const handleChangeFileBlock = (data) => {
    const nameArr = data.id.split('_');
    const element = nameArr[nameArr.length - 2];
    const change_param = nameArr[nameArr.length - 1];

    const bloques = state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            [element]: data,
          },
        };
      } else {
        return bloque;
      }
    });

    setState((prevState) => ({
      ...prevState,
      uploading_images: data.uploading,
      bloques: bloques,
    }));
  };

  const handleSortBlocks = (bloques) => {
    setState((prevState) => ({
      ...prevState,
      bloques: bloques,
    }));
  };

  const handleDisableEnableBlock = (idx) => {
    const bloques = state.bloques.map((bloque, index) => {
      return index === idx
        ? {
            ...bloque,
            bl_activo: {
              ...bloque.activo,
              value: bloque.bl_activo.value ? 0 : 1,
            },
          }
        : bloque;
    });

    setState((prevState) => ({
      ...prevState,
      bloques: bloques,
    }));
  };

  const addBlock = (newBlock) => {
    setState((prevState) => ({
      ...prevState,
      bloques: [...prevState.bloques, newBlock],
      agregar: {
        ...prevState.agregar,
        bl_tipo: { ...prevState.agregar.bl_tipo, value: 0 },
      },
    }));
  };

  const showAlertMessage = (message) => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        open: true,
        type: 'error',
        message,
      },
    }));
  };

  const handleAddBlock = (e) => {
    e.preventDefault();
    const { bloques, agregar } = state;
    const bl_tipo = agregar.bl_tipo.value;

    if (bloques.length >= 12) {
      showAlertMessage('La cantidad máxima de bloques es 12');
      return;
    }

    const isProductFeed = bl_tipo === 2;
    const hasProductFeedBlock = bloques.some(
      (bloque) => bloque.bl_tipo.value === 2
    );

    if (isProductFeed && hasProductFeedBlock) {
      showAlertMessage(
        'No se puede tener más de un feed principal de productos, agregá un conjunto de productos en su lugar'
      );
      return;
    }

    const newBlock = loadBlock(bl_tipo, bloques.length);
    addBlock(newBlock);
    handleChangeMenuTab(null, bloques.length);
  };

  const handleChangeGridDesign = (type, change_param, max_files) => {
    const bloques = state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        const input = {
          ...bloque.bl_data.type,
          value: type,
        };

        let files = bloque.bl_data.items.files;

        if (files.length > max_files) {
          //remuevo los sobrantes
          files = files.slice(0, max_files);
        } else {
          //agrego imagenes faltantes
          let faltantes = max_files - files.length;
          for (let i = 0; i < faltantes; i++) {
            files = [
              ...files,
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
            ];
          }
        }

        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            type: input,
            items: {
              ...bloque.bl_data.items,
              files: files,
            },
          },
        };
      } else {
        return bloque;
      }
    });

    setState((prevState) => ({
      ...prevState,
      bloques: bloques,
    }));
  };

  const handleDeleteBlock = (e, idx) => {
    e.preventDefault();

    const bloques = state.bloques.filter((_bloque, index) => idx !== index);
    setState((prevState) => ({
      ...prevState,
      bloques: bloques,
    }));
  };

  const handleChangeFeedProducts = (products_feed) => {
    setState((prevState) => ({
      ...prevState,
      products_feed: products_feed,
    }));
  };

  const handleChangePreviewWidth = (width) => {
    setState((prevState) => ({
      ...prevState,
      preview_width: width,
    }));
  };

  const handleUpdatePreview = () => {
    if (!state.uploading_images) {
      setState((prevState) => ({
        ...prevState,
        loading_preview_html: true,
        loadingSpinnerMessage: 'Cargando previsualización...',
      }));

      let { settings, bloques } = state;

      if (Validate.validar_formulario(settings)) {
        let flag = true;
        let bloques_error = [];
        bloques.forEach((bloque, index) => {
          if (
            !(
              Validate.validar_formulario(bloque) &&
              Validate.validar_formulario(bloque.bl_data)
            )
          ) {
            flag = false;
            bloques_error = [
              ...bloques_error,
              bloque.bl_nombre.value
                ? bloque.bl_nombre.value
                : 'Sección ' + (index + 1),
            ];
          } else {
            if ('image' in bloque.bl_data) {
              if (!bloque.bl_data.image.auth) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              }
            } else if ('items' in bloque.bl_data) {
              if (!bloque.bl_data.items.auth) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              } else {
                if (bloque.bl_tipo.value === 11) {
                  let type = bloque.bl_data.type.value;
                  let cantidad_imagenes = bloque.bl_data.items.files.length;
                  if (
                    (type === 0 && cantidad_imagenes !== 2) ||
                    (Validate.in_array(type, [1, 2]) &&
                      cantidad_imagenes !== 3) ||
                    (Validate.in_array(type, [3, 4]) &&
                      cantidad_imagenes !== 4) ||
                    (type === 5 && cantidad_imagenes !== 5)
                  ) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value +
                          ' (debes cargar todas las imágenes)'
                        : 'Sección ' +
                          (index + 1) +
                          ' (debes cargar todas las imágenes)',
                    ];
                  }
                }
              }
            } else if ('products' in bloque.bl_data) {
              if (bloque.bl_data.products.length === 0) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              }
            }
          }
        });

        if (flag) {
          let body = obtenerObjetoBody();

          generateShopTemplatePreview(body, (err, resp) => {
            if (err) {
              setState((prevState) => ({
                ...prevState,
                error: true,
                loading_preview_html: false,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                loading_preview_html: false,
                preview_html: resp.data.html,
              }));
            }
          });
        } else {
          bloques = bloques.map((bloque) => {
            bloque = Validate.validar_formulario_actualizar_obj_sync(bloque);
            let bl_data = Validate.validar_formulario_actualizar_obj_sync(
              bloque.bl_data
            );
            return {
              ...bloque,
              bl_data: bl_data,
            };
          });

          const message =
            bloques_error.length >= 2
              ? 'Por favor, verificar los campos de las secciones: ' +
                bloques_error.join(', ')
              : 'Por favor, verificar los campos de la sección "' +
                bloques_error[0] +
                '"';

          setState((prevState) => ({
            ...prevState,
            loading_preview_html: false,
            bloques: bloques,
            alert: {
              open: true,
              type: 'error',
              message: message,
            },
          }));
        }
      } else {
        settings = Validate.validar_formulario_actualizar_obj_sync(settings);
        setState((prevState) => ({
          ...prevState,
          loading_preview_html: false,
          settings: settings,
          alert: {
            open: true,
            type: 'error',
            message: 'Por favor, verificar los campos señalados con error.',
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que se carguen las imágenes',
        },
      }));
    }
  };

  const obtenerObjetoBloques = () => {
    const bloques = state.bloques.map((bloque) => {
      let bloque_body = {
        idBloques: bloque.idBloques.value,
        bl_activo: bloque.bl_activo.value,
        bl_tipo: bloque.bl_tipo.value,
        bl_nombre: bloque.bl_nombre.value,
        bl_data: {},
      };

      Object.keys(bloque.bl_data).forEach((bl_data_key) => {
        switch (bl_data_key) {
          case 'items':
            bloque_body.bl_data['items'] = bloque.bl_data[bl_data_key].files;
            break;

          case 'image':
            bloque_body.bl_data['image'] = bloque.bl_data[bl_data_key].files[0];
            break;

          case 'products':
            bloque_body.bl_data['products'] = bloque.bl_data[bl_data_key].map(
              (producto) => producto.idProductos
            );
            break;

          default:
            bloque_body.bl_data[bl_data_key] =
              bloque.bl_data[bl_data_key].value;
            break;
        }
      });
      return bloque_body;
    });
    return bloques;
  };

  const obtenerObjetoBody = () => {
    let { settings, products_feed } = state;
    products_feed = products_feed.map((producto) => producto.idProductos);

    let body = {
      Templates_idTemplates: settings.template_id.value,
      t_logo: settings.t_logo.files.length ? settings.t_logo.files[0].link : '',
      t_favicon: settings.t_favicon.files.length
        ? settings.t_favicon.files[0].link
        : 'favicon.png',
      t_template_data: {
        id: settings.color.value,
        css: settings.css.value,
        body: {
          background: settings.body_background.value,
        },
        fonts: {
          text: settings.font_text.value,
          title: settings.font_title.value,
        },
        primary: {
          text: settings.primary_text.value,
          background: settings.primary_background.value,
          contrastText: settings.primary_contrastText.value,
        },
        secondary: {
          text: settings.secondary_text.value,
          background: settings.secondary_background.value,
          contrastText: settings.secondary_contrastText.value,
        },
        message: {
          text: settings.message_text.value,
          link: settings.message_link.value,
        },
        products_feed: {
          order: settings.products_feed_order.value,
          filter: settings.products_feed_filter.value,
          masonry: 0,
          product_subtext: settings.products_feed_product_subtext.value,
          product_subtext_type:
            settings.products_feed_product_subtext_type.value,
          product_subtext_cuotas:
            settings.products_feed_product_subtext_cuotas.value,
        },
        products_detail: {
          photo_display: settings.products_detail_photo_display.value,
          photo_zoom: settings.products_detail_photo_zoom.value,
        },
        checkout: {
          comments: settings.checkout_comments.value,
          short_billing_form: settings.checkout_short_billing_form.value,
          step_review: settings.checkout_step_review.value,
        },
        other: {
          border_radius: settings.other_border_radius.value,
          menu_label_products: settings.other_menu_label_products.value,
          menu_label_wholesaler: settings.other_menu_label_wholesaler.value,
          menu_type: 0,
        },
        popup: {
          active: 0,
          type: 0,
          visibility: 0,
          visibility_place: 0,
          title: '',
          image: '',
          text: '',
          link: '',
          button: '',
          html: '',
        },
      },
      products_feed: products_feed,
      bloques: obtenerObjetoBloques(),
    };
    return body;
  };

  const handleSubmit = (e, redirectToHome = false) => {
    e.preventDefault();

    if (!state.uploading_images) {
      setState((prevState) => ({
        ...prevState,
        loadingSpinnerMessage: 'Validando datos...',
        saving_changes: true,
      }));

      let { settings, bloques } = state;

      if (Validate.validar_formulario(settings)) {
        let flag = true;
        let bloques_error = [];
        bloques.forEach((bloque, index) => {
          if (
            !(
              Validate.validar_formulario(bloque) &&
              Validate.validar_formulario(bloque.bl_data)
            )
          ) {
            flag = false;
            bloques_error = [
              ...bloques_error,
              bloque.bl_nombre.value
                ? bloque.bl_nombre.value
                : 'Sección ' + (index + 1),
            ];
          } else {
            if ('image' in bloque.bl_data) {
              if (!bloque.bl_data.image.auth) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              }
            } else if ('items' in bloque.bl_data) {
              if (!bloque.bl_data.items.auth) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              } else {
                if (bloque.bl_tipo.value === 11) {
                  let type = bloque.bl_data.type.value;
                  let cantidad_imagenes = bloque.bl_data.items.files.length;
                  if (
                    (type === 0 && cantidad_imagenes !== 2) ||
                    (Validate.in_array(type, [1, 2]) &&
                      cantidad_imagenes !== 3) ||
                    (Validate.in_array(type, [3, 4]) &&
                      cantidad_imagenes !== 4) ||
                    (type === 5 && cantidad_imagenes !== 5)
                  ) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value +
                          ' (debes cargar todas las imágenes)'
                        : 'Sección ' +
                          (index + 1) +
                          ' (debes cargar todas las imágenes)',
                    ];
                  }
                }
              }
            } else if ('products' in bloque.bl_data) {
              if (bloque.bl_data.products.length === 0) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              }
            }
          }
        });

        if (flag) {
          const body = obtenerObjetoBody();
          const templateId = body.Templates_idTemplates;
          createAmplitudeEventWithDevice(
            design_amplitude_events.diseño_selecciona_guardar.event_name,
            {
              plantilla: getTemplateName(templateId),
            }
          );
          setState((prevState) => ({
            ...prevState,
            loadingSpinnerMessage: 'Guardando cambios...',
            saving_changes: true,
          }));

          updateShopTemplate(body, (err, resp) => {
            if (err) {
              setState((prevState) => ({
                ...prevState,
                saving_changes: false,
                alert: {
                  open: true,
                  type: 'error',
                  message: resp.message,
                },
              }));
            } else {
              if (body?.t_logo && idCountry === 'ARG') {
                braze.logCustomEvent('empretienda_diseño_carga_logo');
              }

              fetchBlocks(async (err, responseBlocks) => {
                if (err) {
                  setState((prevState) => ({
                    ...prevState,
                    saving_changes: false,
                    alert: {
                      open: true,
                      type: 'error',
                      message: responseBlocks.message,
                    },
                  }));
                } else {
                  //mapeo los bloques
                  let loadedBlocks = responseBlocks.data.map((bloque) => {
                    return loadBlock(
                      bloque.bl_tipo,
                      state.bloques.length,
                      true,
                      bloque
                    );
                  });

                  if (redirectToHome) {
                    await handleUpdatePreview();
                    await createAmplitudeEventWithDevice(
                      design_amplitude_events.diseño_selecciona_guardar
                        .event_name
                    );

                    localStorage.setItem(
                      'show-alert',
                      JSON.stringify({
                        open: true,
                        type: 'success',
                        message: resp.message,
                      })
                    );

                    router.push('/');
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      saving_changes: false,
                      alert: {
                        open: true,
                        type: 'success',
                        message: resp.message,
                      },
                      bloques: loadedBlocks,
                    }));
                    handleUpdatePreview();
                  }
                }
              });
            }
          });
        } else {
          bloques = bloques.map((bloque) => {
            bloque = Validate.validar_formulario_actualizar_obj_sync(bloque);
            let bl_data = Validate.validar_formulario_actualizar_obj_sync(
              bloque.bl_data
            );
            return {
              ...bloque,
              bl_data: bl_data,
            };
          });

          const message =
            bloques_error.length >= 2
              ? 'Por favor, verificar los campos de las secciones: ' +
                bloques_error.join(', ')
              : 'Por favor, verificar los campos de la sección "' +
                bloques_error[0] +
                '"';

          setState((prevState) => ({
            ...prevState,
            saving_changes: false,
            bloques: bloques,
            alert: {
              open: true,
              type: 'error',
              message: message,
            },
          }));
        }
      } else {
        settings = Validate.validar_formulario_actualizar_obj_sync(settings);

        setState((prevState) => ({
          ...prevState,
          saving_changes: false,
          settings: settings,
          alert: {
            open: true,
            type: 'error',
            message: 'Por favor, verificar los campos señalados con error.',
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que se carguen las imágenes',
        },
      }));
    }
  };

  if (
    state.loading_tienda ||
    state.loading_bloques ||
    state.loading_productos_ord ||
    state.loading_preview_html ||
    state.saving_changes
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          margin: '0 auto',
          flexDirection: 'column',
        }}
      >
        <CircularProgress color="primary" />
        <Typography
          sx={{
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          {state.loadingSpinnerMessage}
        </Typography>
      </Box>
    );
  } else if (state.error) {
    return (
      <Box m="50px 20px">
        <ErrorLayout url="/builder" />
      </Box>
    );
  } else {
    return (
      <>
        <AlertUI
          open={state.alert.open}
          message={state.alert.message}
          type={state.alert.type}
          handleCloseAlert={handleCloseAlert}
        />
        <BuilderWrapper fonts={state.selects.fonts}>
          <NavigationLayout
            openCloseDrawer={toggleDrawerOpen}
            handleSubmit={handleSubmit}
            handleChangePreviewWidth={handleChangePreviewWidth}
            handleUpdatePreview={handleUpdatePreview}
            deviceActive={state.deviceActive}
            handleChangeDeviceActive={(device) =>
              setState((prevState) => ({ ...prevState, deviceActive: device }))
            }
          />
          <DrawerLayout
            openCloseDrawer={toggleDrawerOpen}
            drawer_status={state.drawer_status}
            drawer_title={state.drawer_title}
            back_page={state.back_page}
            handleUpdatePreview={handleUpdatePreview}
          >
            <DrawerContent
              active_page={state.active_page}
              tienda={tienda}
              settings={state.settings}
              agregar={state.agregar}
              selects={state.selects}
              bloques={state.bloques}
              productos={state.products_feed}
              handleChange={handleChange}
              handleChangeMenuTab={handleChangeMenuTab}
              handleChangeBlock={handleChangeBlock}
              handleDeleteBlock={handleDeleteBlock}
              handlerShowAlertUI={(alert) => {
                setState((prevState) => ({
                  ...prevState,
                  alert: alert,
                }));
              }}
              handleChangeSelect={handleChangeSelect}
              handleAddBlock={handleAddBlock}
              handleSortBlocks={handleSortBlocks}
              handleChangeFileBlock={handleChangeFileBlock}
              handleChangeGridDesign={handleChangeGridDesign}
              handleChangeSelectBlock={handleChangeSelectBlock}
              handleDisableEnableBlock={handleDisableEnableBlock}
              handleChangeFileSettings={handleChangeFileSettings}
              handleChangeFeedProducts={handleChangeFeedProducts}
              handleChangeProductsBlock={handleChangeProductsBlock}
            />
          </DrawerLayout>
          <ShopPreview
            preview_width={state.preview_width}
            preview_html={state.preview_html}
          />
        </BuilderWrapper>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
    fetchBlocks: (callback) => dispatch(bloqueObtenerBloques(callback)),
    updateShopTemplate: (data, callback) =>
      dispatch(tiendaActualizarTemplate(data, callback)),
    generateShopTemplatePreview: (data, callback) =>
      dispatch(tiendaGenerarPrevisualizacionTemplate(data, callback)),
    getSortedProducts: (callback) =>
      dispatch(productoObtenerProductosOrdenados(callback)),
  };
};

const BuilderPage = connect(mapStateToProps, mapDispatchToProps)(BuilderPage_);

export default BuilderPage;
