import React from 'react';
import {
  Box,
  Button,
  Drawer,
  Hidden,
  Icon,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../../components/amplitude';

const DrawerWrapper = ({ children, openCloseDrawer, handleUpdatePreview }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          ...theme.mixins.toolbar,
          color: 'rgba(0, 0, 0, 0.87)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          padding: '0px 10px 0px 5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <IconButton
            color="inherit"
            aria-label="Close drawer"
            onClick={openCloseDrawer}
          >
            <Icon>close</Icon>
          </IconButton>
        </Box>
        <Button
          size="small"
          color="primary"
          fullWidth={false}
          variant="outlined"
          onClick={async (e) => {
            await handleUpdatePreview(e);
            openCloseDrawer(e);
            createAmplitudeEventWithDevice(
              design_amplitude_events.diseño_selecciona_ver_cambios.event_name
            );
          }}
          sx={{ padding: '4px 24px' }}
        >
          Ver cambios
        </Button>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export const DrawerLayout = ({
  children,
  back_page,
  drawer_title,
  drawer_status,
  openCloseDrawer,
  handleUpdatePreview,
}) => {
  let drawer = (
    <DrawerWrapper
      back_page={back_page}
      drawer_title={drawer_title}
      openCloseDrawer={openCloseDrawer}
      handleUpdatePreview={handleUpdatePreview}
    >
      {children}
    </DrawerWrapper>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { xs: 'unset', md: '300px' },
        flexShrink: { xs: 'unset', md: 0 },
        zIndex: 1,
      }}
    >
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="left"
          variant="temporary"
          open={drawer_status}
          onClose={openCloseDrawer}
          sx={{ width: '300px', overflowX: 'hidden' }}
          PaperProps={{
            sx: {
              width: '300px',
              overflowX: 'hidden',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open
          PaperProps={{
            sx: {
              width: '300px',
              overflowX: 'hidden',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Box>
  );
};
